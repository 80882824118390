import React, { useRef } from "react";
import BaseLayout from "../../BaseLayout";
import SEO from "../../seo";
// import Avantages from "../avantage/Avantages";
import WorkHere from "../workHere/workHere";
import WorkWith from "../workWith/workWith";
import CandidatureSpontanee from "../candidatureSpontanee/CandidatureSpontanee";
import ImageHeaderSubPage from "../offerDetail/ImageHeaderSubPage";
import PostOfferPerCity from "../postOfferPerCity/PostOfferPerCity";
import DescriptionUsine from "../descriptionUsine/DescriptionUsine";

function PageUsine({ pageContext: { NosUsine, usine } }) {
  console.log(usine, "usine");
  console.log(NosUsine, "NosUsine");
  const childRef = useRef();

  return (
    <BaseLayout>
      <ImageHeaderSubPage />

      {/* {console.log(offer, "offer")}
      <SEO
        description={`${CategoriesData[offer.categorie].descriptionSEO}`}
        title={`${CategoriesData[offer.categorie].pageTitleSEO}`}
      />
      <$CategoriesDetail> */}
      <DescriptionUsine NosUsine={NosUsine} usine={usine} />
      <PostOfferPerCity />
      <CandidatureSpontanee childRef={childRef} />
      {/* <WorkWith NosUsine={NosUsine} usine={usine} /> */}
      <WorkHere NosUsine={NosUsine} usine={usine} />
      {/* <Avantages /> */}
      {/* </$CategoriesDetail> */}
    </BaseLayout>
  );
}

export default PageUsine;
