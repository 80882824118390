import styled, { css } from "styled-components";
import imageManCircleConstruction from "../../../../static/travailleur-bois.png";
import ForestDegrade from "../../../../static/foret-degrade.jpg";

export const $WorkWith = styled.div`
  width: 100%;
  padding: 1em 4em;
  .main-container {
    display: flex;
    justify-content: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .picture-container {
      text-align: center;
      display: flex;
      width: 100%;
      .img-container {
        position: relative;
        height: 0;
        margin: 1em;
        padding-top: 17%;
        background-color: red;
        width: calc(100% / 3);
      }
    }
  }
`;

export const $ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url(${props => props.imgBackground}) no-repeat;
  background-size: cover;
`;
