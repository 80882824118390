import styled, { css } from "styled-components";
import imageManCircleConstruction from "../../../../static/travailleur-bois.png";
import ForestDegrade from "../../../../static/foret-degrade.jpg";

export const $WorkHere = styled.div`
  background: url(${ForestDegrade}) no-repeat bottom;
  background-size: cover;
  z-index: -1;
  /* padding: 1em 16%; */
  margin: 0 auto 0;
  height: 43.5em;
  width: 100%;
  position: relative;
  @media only screen and (max-width: 768px) {
    margin: 3em auto 0;
    height: auto;
  }
  &:before {
    content: "";
    display: inline-block;
    background: url(${imageManCircleConstruction}) no-repeat;
    background-size: contain;
    left: 0;
    top: 0;
    transform: translateY(-3em);
    width: 35em;
    height: 35em;
    max-width: 40%;
    z-index: 1;
    position: absolute;

    @media only screen and (max-width: 575px) {
      display: none;
    }

    @media only screen and (min-width: 769px) {
      top: 50%;
      transform: translate(-3em, -50%);
    }

    @media only screen and (max-width: 1024px) {
      width: 20em;
      height: 20em;
    }
  }

  .main-container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: auto;

    @media only screen and (max-width: 768px) {
      padding-top: 8em;
      transform: translateY(0);
    }
    @media only screen and (max-width: 575px) {
      padding-top: 2em;
    }
    .inner-text {
      display: flex;
      justify-content: center;
      h3 {
        width: 80%;
        text-align: right;
        /* margin: 0 0 0 auto; */
        /* font-size: 40px; */
        position: relative;
        text-transform: uppercase;
        font-weight: bold;

        @media only screen and (max-width: 1024px) {
          width: 90%;
        }
        @media only screen and (max-width: 575px) {
          text-align: center;
        }

        /* @media only screen and (max-width: 768px) {
          font-size: 35px;
        }
        @media only screen and (max-width: 425px) {
          font-size: 30px;
        } */
        span {
          position: relative;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 80px;
            height: 80px;
            background-color: fff;
            border: solid 1px #118738;
            border-radius: 50em;
            transform: translate(-27%, -25%);
          }
        }
      }
      p {
        font-size: 16px;
        width: 55%;
        margin: 3em 0 3em auto;
        border-radius: 14px;

        @media only screen and (max-width: 1024px) {
          padding: 0.5em;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
  .box {
    display: flex;
    justify-content: center;
    font-size: 16px;
    ul {
      left: 50%;
      /* transform: translateX(-50%); */
      width: 80%;
      height: auto;
      background: transparent linear-gradient(90deg, #eb5637 0%, #fd886f 100%)
        0% 0% no-repeat padding-box;
      opacity: 1;
      padding: 2em;
      @media only screen and (max-width: 1024px) {
        width: 90%;
      }
      li {
        margin: 0 0 1em auto;
        width: 65%;
        color: #fff;

        &:nth-child(1) {
          @media only screen and (max-width: 575px) {
            width: 100%;
          }
        }

        &:nth-child(2) {
          @media only screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }
`;
