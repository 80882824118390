import React from "react";
import BaseLayout from "../../BaseLayout";
import SEO from "../../seo";
import CategoriesData from "../../../client/data/categoriesData.json";
import { $PostOfferPerCity } from "./SC_PostOfferPerCity";

import { ReactSVG } from "react-svg";

const PostOfferPerCity = ({ childRef }) => {
  return (
    <$PostOfferPerCity>
      <div className="poste-container">
        <h3 className="title-post">Postes disponibles</h3>
        <div className="post-list">
          <span className="post">
            {" "}
            <ReactSVG
              className="icon-advantage"
              src={`../../../../SVG/arrow-list.svg`}
            ></ReactSVG>
            aaaaaaaaaaaaaa
          </span>
          <span className="post">
            {" "}
            <ReactSVG
              className="icon-advantage"
              src={`../../../../SVG/arrow-list.svg`}
            ></ReactSVG>
            bbbbbbbbbbbbbb
          </span>
          <span className="post">
            {" "}
            <ReactSVG
              className="icon-advantage"
              src={`../../../../SVG/arrow-list.svg`}
            ></ReactSVG>
            cccccccccccccc
          </span>
        </div>
      </div>
    </$PostOfferPerCity>
  );
};

export default PostOfferPerCity;
