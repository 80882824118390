import styled, { css } from "styled-components";
import img from "../../../../static/image-directeur.jpg";
export const $DescriptionUsine = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2em 0em;
  font-size: 14px;
  min-height: 42em;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }

  .description-container {
    width: 100%;
    padding: 0 1em;

    @media only screen and (min-width: 1025px) {
      padding-left: 5em;
      width: 60%;
    }

    .paragraphe {
      display: block;
      padding: 0.5em 1em;
    }

    .operations {
      margin: 2em 0 0;
      padding: 1em 0;
      background: transparent linear-gradient(90deg, #16a247 0%, #97d8b2 100%)
        0% 0% no-repeat padding-box;

      h3 {
        text-align: center;
        text-transform: uppercase;
      }

      .menu-operation {
        background-color: rgba(000, 000, 000, 25%);
        display: flex;
        justify-content: space-around;

        span {
          font-size: 1.2em;
          padding: 0.5em 0;
          color: #fff;
          font-weight: bold;
          width: calc(100% / 3);
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          &.true {
            background-color: rgba(000, 000, 000, 40%);
          }
        }
      }
      .operation-text {
        padding: 1em 2em;

        .text {
          &.true {
            opacity: 1;
            transition: 0.3s ease-in-out;
            height: auto;
          }

          &.false {
            height: 0;
            opacity: 0;
          }
        }
      }
    }
  }
  .directeur-container {
    overflow: auto;
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    .inner-text {
      position: relative;
      padding: 2em 1em 2em 1em;
      color: #fff;
      background: transparent linear-gradient(271deg, #fc866c 0%, #f1674a 100%)
        0% 0% no-repeat padding-box;

      @media only screen and (max-width: 1024px) {
        margin: 15em 1em 1em;
        padding: 8em 1em 2em 1em;
      }
      .inner-text-title {
        @media only screen and (max-width: 1024px) {
          display: flex;
          align-items: center;
        }
        .icon-bulle {
          div {
            svg {
              @media only screen and (max-width: 1024px) {
                height: 5em;
              }
            }
          }
        }
        h3 {
          padding: 0.5em 0;
          text-transform: uppercase;
        }
      }
      &:before {
        content: "";
        background: url(${img}) no-repeat;
        background-size: cover;
        height: 26em;
        width: 26em;
        border-radius: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-20%, -60%);
        @media only screen and (max-width: 1024px) {
          transform: translate(-50%, -60%);
          height: 20em;
          width: 20em;
        }
      }
    }
  }
`;
